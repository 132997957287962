<template>
  <div>
    <b-form-group label="Title">
      <b-form-input placeholder="Enter Title" v-model="content.title" />
    </b-form-group>
    <span>Sentences</span>
    <div class="border border-dark round p-1" >
      <div v-for="(sentence) in content.sentences" :key="sentence.temp_id || sentence._id"
        class="d-flex align-items-center mt-50"
      >
        <b-form-input placeholder="Enter Text" v-model="sentence.text" />
        <b-button size="sm" class="btn-icon ml-50" variant="danger"
          @click="removeSentence"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>

      <div class="d-flex justify-content-center mt-1">
        <b-button variant="flat-success"
          @click="addSentence"
        >
          <feather-icon icon="PlusIcon" />
          <span>Add Sentence</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import _ from 'lodash'

export default defineComponent({
  setup() {},
  props: {
    content: { type: Object, required: true },
  },
  methods: {
    addSentence() {
      let content = { text: '', temp_id: _.uniqueId('topic_content_item-') };
      this.content.sentences.push(content)
    },
    removeSentence(sentenceIndex) {
      this.content.sentences.splice(sentenceIndex, 1)
    },
  }
})
</script>

