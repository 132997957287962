<template>
  <div class="page-content">
    <page-breadcrumb v-if="item" title="Topic Detail" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="item">
        <form-generator :model="item" :schema="item_form_schema" />
      </b-card>
    </section>

    <section class="mb-2" v-if="item">
      <h2 class="mb-2">Content</h2>
      <div class="my-2">
        <app-collapse type="shadow" class="mt-2 border p-1">
          <draggable v-model="item.content" class="list-group list-group-flush" tag="ul"
            handle=".sentence-grab-button"
          >
            <transition-group type="transition" name="list">
              <app-collapse-item v-for="(group, groupIndex) in item.content" :key="group.temp_id || group._id"
                class="content-item" :title="group.title" :is-visible="groupIndex === item.content.length - 1"
              >
                <template slot="header">
                  <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                    <div class="d-flex align-items-center w-100 group-grab-button" style="cursor: grab;">
                      <feather-icon icon="TargetIcon" />
                      <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ group.title || groupIndex + 1 }}</span>
                    </div>
                    <div style="position: absolute; right: 30px; ">
                      <b-button variant="flat-danger" class="btn-icon rounded-circle"
                        @click.stop="removeContent(groupIndex)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>
                  </div>
                </template>
                <topic-content-item :content="group" />
              </app-collapse-item>
            </transition-group>
          </draggable>

          <div v-if="!item.content.length" class="text-center"><span>No content!</span></div>
        </app-collapse>
      </div>
      <div class="d-flex justify-content-center">
        <b-button variant="success"
          @click="addContent"
        >
          <feather-icon icon="PlusIcon" />
          Add Content
        </b-button>
      </div>
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import _ from 'lodash'

import service from '../service';
import { TOPIC_TYPE_OPTIONS } from '../constant';
import TopicContentItem from './_component/TopicContentItem.vue'

const item_form_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Thumbnail', field: 'thumbnail', input_type: 'image', validate: { required: true } },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Title', field: 'title', validate: { required: true } },
      { label: 'Type', field: 'topic_type', input_type: 'select', options: TOPIC_TYPE_OPTIONS },
      { label: 'Display order', field: 'display_order', validate: { required: true } }
    ]
  }
];

export default {
  components: {
    draggable,
    TopicContentItem
  },
  data(){
    return{
      item_form_schema,
      item: null,
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Topics',
          to: { name: 'social-topic-list' },
        },
        {
          text: `${this.item ? this.item.title : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    this.getGroup();
  },
  methods: {
    uniqueId(prefix) {
      return _.uniqueId(prefix);
    },
    // group
    async getGroup(){
      this.item = await service.get({id: this.itemId});
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item),
      });
      this.getGroup();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "social-topic-list" });
    },
    // content
    addContent() {
      let content = { title: '', sentences: [], temp_id: this.uniqueId('topic_content_item-') };
      this.item.content.push(content)
    },
    removeContent(sentenceIndex) {
      this.item.content.splice(sentenceIndex, 1)
    },
  }
}
</script>
